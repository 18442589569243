<template>
  <ListPage
    :article-list="articleList"
    :offset="offset"
    :total="total"
    :size="size">
    <template v-slot:summery>
      <CategoryWidget
        :name="titleAndUrl.url"
        :description="$pubCtx.categoryDescription"
        sub-type="category"/>
    </template>
  </ListPage>
</template>

<script>
import ListPage from './page-segments/ListPage';

export default {
  name: 'category-list-page',
  dataResolver: 'categoryListPage',
  components: {
    ListPage
  },
  data() {
    return {
      articleList: this.$store.state.articleList,
      offset: parseInt(this.$store.state.offset),
      total: parseInt(this.$store.state.total),
      size: parseInt(this.$store.state.size)
    };
  },
  computed: {
    titleAndUrl() {
      if (this.$route.meta &&
        this.$route.meta.mainCategory &&
        this.$route.meta.mainCategory.label
      ) {
        return { title: this.$route.meta.mainCategory.label, url: this.$route.meta.mainCategory.url };
      }
      return { title: this.$store.state.pageTitle, url: this.$store.state.pageTitle.toLowerCase().replace(' ', '_') };
    }
  }
};
</script>
